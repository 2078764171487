<template>
  <div>
    <Button
      class="hidden md:inline-flex justify-center items-center gap-x-2 w-full absolute bottom-0 left-0 z-[2]"
      @click="computedNotifyMeFormState = NotifyMeFormState.ON_EMAIL_FILL"
      v-show="computedNotifyMeFormState !== NotifyMeFormState.SUBSCRIBED"
    >
      <IconEmail class="stroke-white shrink-0" />
      {{ $t("product.card.notifyMe") }}
    </Button>

    <Transition name="fade" mode="out-in">
      <div
        v-show="computedNotifyMeFormState === NotifyMeFormState.ON_EMAIL_FILL"
        class="absolute top-0 left-0 w-full h-full bg-black/[0.33] z-[5]"
      ></div>
    </Transition>
    <Transition name="slide-up" mode="out-in">
      <div
        v-show="computedNotifyMeFormState === NotifyMeFormState.ON_EMAIL_FILL"
        v-on-click-outside="handleOnClickOutside"
        class="absolute bottom-0 left-0 w-full z-[6] space-y-2.5 p-4 bg-light-grey"
      >
        <div class="flex justify-end">
          <button
            @click="computedNotifyMeFormState = NotifyMeFormState.DEFAULT"
          >
            <IconClose class="w-6 h-6" />
          </button>
        </div>
        <p class="text-m-medium" v-html="$t('product.soldOutText')"></p>

        <form class="space-y-4" @submit.prevent="onSubmit">
          <Input
            v-model="formValues.email"
            :label="$t('form.emailPlaceholder')"
            :id="fieldNames['email']"
            :name="fieldNames['email']"
            type="email"
            class="w-full"
            input-class-names="!bg-light-grey"
            :is-error="v$.email.$errors?.length > 0"
            :support-text="v$.email.$errors?.[0]?.$message as string"
          />
          <Button type="submit" class="h-full w-full !py-3 font-medium">
            {{ $t("general.formSubmit") }}
          </Button>
        </form>
        <p
          ref="$richTextWrapper"
          class="notify-me__legal-links !text-m-sm"
          v-html="$t('product.notifyMeLegalLinks', computedShopPageHrefs)"
        ></p>
      </div>
    </Transition>

    <FeedbackBanner
      v-show="computedNotifyMeFormState === NotifyMeFormState.SUBSCRIBED"
      class="absolute bottom-0 left-0 w-full hidden md:block"
    >
      <p
        class="text-validation-green"
        v-html="$t('product.soldOutEmailSentText')"
      ></p>
    </FeedbackBanner>
  </div>
</template>

<script setup lang="ts">
import { computed, reactive, toRefs } from "vue";
import Button from "@/components/atoms/Button";
import { IconEmail, IconClose } from "@/components/atoms/Icon";
import { NotifyMeFormState } from "@/types/forms";
import useVuelidate from "@vuelidate/core";
import { vOnClickOutside } from "@vueuse/components";
import { formProductNotifyMeValidationRules } from "@/validation-rules/formProductNotifyMe";
import FeedbackBanner from "@/components/atoms/FeedbackBanner";
import Input from "@/components/atoms/Input";
import { useSystemConfigBasicInformationStore } from "@/stores";

import { fieldNames } from "@/constants/fieldNames";

const props = defineProps<{
  notifyMeFormState: NotifyMeFormState;
}>();

const emit = defineEmits(["notifyMeFormSubmit", "update:notifyMeFormState"]);

const computedNotifyMeFormState = computed({
  get: () => props.notifyMeFormState,
  set: (value: NotifyMeFormState) => emit("update:notifyMeFormState", value),
});

const { computedShopPageHrefs } = toRefs(
  useSystemConfigBasicInformationStore(),
);

const formValues = reactive({
  email: "",
});
const v$ = useVuelidate(formProductNotifyMeValidationRules(), formValues);

const onSubmit = () => {
  v$.value.$validate();

  const hasFormErrors = v$.value.$errors.length > 0;

  if (hasFormErrors) {
    return;
  }

  emit("notifyMeFormSubmit", formValues);
};

const handleOnClickOutside = () => {
  if (computedNotifyMeFormState.value !== NotifyMeFormState.ON_EMAIL_FILL)
    return;

  computedNotifyMeFormState.value = NotifyMeFormState.DEFAULT;
};
</script>
